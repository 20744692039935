import { createApi } from "@reduxjs/toolkit/query/react";
import { current } from "@reduxjs/toolkit";
import {
  UpdateEmployeeStatus,
  ManageRoleEmployee,
  UpdateEmployerDetails,
  EmployeeList,
  SearchParam,
  UpdateEmployeeData,
  ViewEmployeeData,
  DeleteEmployeeResponse,
  DeleteEmployeeQuery,
  ManageRoleEmployeeData,
  ChangeStatus,
  AddEmployee,
  AddEmployeeResponse,
  DownloadSampleFileParam,
  DownloadEmployeeResponse,
  PensionFundList,
  GetPassowrdLinkResponse,
  ResetPasswordLinkInfo,
  UnblockUserResponse,
  UnblockUser,
} from "../../types/employee";
import isEmpty from "lodash/isEmpty"
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";

export const EMPLOYEe_API_REDUCER_KEY = "employeeApi";

export const employeeApi = createApi({
  reducerPath: EMPLOYEe_API_REDUCER_KEY,
  baseQuery: baseQuery,
  tagTypes: ["Employees"],
  endpoints: (builder) => ({
    getEmployeeList: builder.query<EmployeeList, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        const { pageNo = 1, size = 30, searchText = "", filter } = args;
        const body = { pageNo, size };
        return {
          url: ApiConstant.EMPLOYEE_LISTING,
          method: "POST",
          body: filter?.length ? { ...body, filter } : { ...body, searchText },
        };
      },
      providesTags: ["Employees"],
    }),
    getEmployeeById: builder.query<ViewEmployeeData, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.EMPLOYEE_BY_ID}/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Employees"],
    }),
    downloadSampleEmployeeFile: builder.query<
      DownloadEmployeeResponse,
      Partial<DownloadSampleFileParam>
    >({
      query: (body: DownloadSampleFileParam) => {
        return {
          url: ApiConstant.DOWNLOAD_SAMPLE_FILE,
          method: "POST",
          body,
        };
      },
      providesTags: ["Employees"],
    }),
    manageRoleEmployee: builder.mutation<
      ManageRoleEmployeeData,
      Partial<ManageRoleEmployee>
    >({
      query(body) {
        return {
          url: ApiConstant.MANAGE_ROLE_EMPLOYEE,
          method: "PATCH",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      invalidatesTags: ["Employees"],
    }),
    unblockUser: builder.mutation<UnblockUserResponse, UnblockUser>({
      query(body) {
        return {
          url: `${ApiConstant.UNBLOCK_USER}`,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: any; message: string };
        status: number;
      }) => {
        const error = response?.data?.error
        if (Array.isArray(error) && !isEmpty(error)) {
          return error[0]
        } else {
          return response.data.message
        }
      },
      invalidatesTags: ["Employees"],
    }),
    bulkImportEmployee: builder.mutation<AddEmployeeResponse, AddEmployee>({
      query(body) {
        return {
          url: `${ApiConstant.ADD_ROLE_EMPLOYEE}`,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => response.data.error,
      invalidatesTags: ["Employees"],
    }),
    editEmployee: builder.mutation<UpdateEmployeeData, Partial<UpdateEmployerDetails>>({
      query(body) {
        return {
          url: `${ApiConstant.UPDATE_ROLE_EMPLOYEE}`,
          method: "PATCH",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => response.data.error,
      invalidatesTags: ["Employees"],
    }),
    changeEmployeeStatus: builder.mutation<UpdateEmployeeStatus, ChangeStatus>({
      query: (body: ChangeStatus) => {
        const { EmployerID, EmployeeID, RoleID, IsActive } = body;
        return {
          url: ApiConstant.CHANGE_EMPLOYEE_STATUS,
          method: "POST",
          body: { EmployerID, EmployeeID, RoleID, IsActive },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { EmployerID, EmployeeID, RoleID } = args;

          const { data: updatedInfo } = await queryFulfilled;

          const patchedResult = dispatch(
            employeeApi.util.updateQueryData(
              "getEmployeeById",
              EmployeeID,
              (draft) => {
                console.log("UUData", current(draft.data));

                const EmployeeInfo = draft.data.employee;
                const arrRoles = EmployeeInfo.roles;

                const objIndex = arrRoles.findIndex(
                  (item, index) => item.EmployerID === EmployerID
                );
                if (objIndex !== -1) {
                  const info = arrRoles[objIndex];
                  const arrSubRoles = info.Roles;
                  const objIndexRole = arrSubRoles.findIndex(
                    (item, index) => item.RoleID === RoleID
                  );

                  if (objIndexRole !== -1) {
                    const Roleinfo = arrSubRoles[objIndexRole];

                    Roleinfo.Status = updatedInfo.data.IsActive;
                    arrSubRoles[objIndexRole] = Roleinfo;
                    arrRoles[objIndex].Roles = arrSubRoles;
                  }
                }
              }
            )
          );
          console.log("Pathched", patchedResult);
        } catch (error) {
          console.log("Error", error);
        }
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => response.data.error,
    }),
    deleteEmployee: builder.mutation<
      DeleteEmployeeResponse,
      DeleteEmployeeQuery
    >({
      query: (args: DeleteEmployeeQuery) => {
        const { ID } = args;
        return {
          url: ApiConstant.DELETE_EMPLOYER(ID),
          method: "DELETE",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { ID, ...rest } = args;
          const { data: updatedInfo } = await queryFulfilled;
          console.log("Data", updatedInfo);
          const patchedResult = dispatch(
            employeeApi.util.updateQueryData(
              "getEmployeeList",
              rest,
              (draft) => {
                console.log("UUData", draft.data);
                const objIndex = draft.data.findIndex(
                  (item, index) => item.ID === ID
                );
                if (objIndex !== -1) {
                  draft.data.splice(objIndex, 1);
                }
                console.log("Draft Info", current(draft.data));
              }
            )
          );
          console.log("Pathched", patchedResult);
        } catch (error) {
          console.log("Error", error);
        }
      },
    }),
    getPensionFundList: builder.query<PensionFundList, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.GET_PENSION_FUNDS_LIST}/${id}`,
          method: "GET",
        };
      },
      // providesTags: ["Employees"],
    }),
    getResetPasswordLink: builder.query<
      GetPassowrdLinkResponse,
      Partial<ResetPasswordLinkInfo>
    >({
      query: (body: ResetPasswordLinkInfo) => {
        const { EmployeeID } = body;
        return {
          url: ApiConstant.GET_RESET_PASSWORD_LINK,
          method: "POST",
          body: { EmployeeID },
        };
      },
    }),
  }),
});

export const {
  useGetEmployeeListQuery,
  useGetEmployeeByIdQuery,
  useBulkImportEmployeeMutation,
  useEditEmployeeMutation,
  useChangeEmployeeStatusMutation,
  useDeleteEmployeeMutation,
  useManageRoleEmployeeMutation,
  useUnblockUserMutation,
  useLazyDownloadSampleEmployeeFileQuery,
  useGetPensionFundListQuery,
  useLazyGetResetPasswordLinkQuery,
} = employeeApi;
