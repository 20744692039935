import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { InputLabel, FormControl, FormHelperText, Stack } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FormFieldProps } from ".";
import { dateConstant } from "constants/ConstantVariables";
import { useState } from "react";

export interface DateControlProps extends FormFieldProps, Omit<DatePickerProps<Date>, 'label'> {
  value: Date | null;
  onChange: (value: Date | null) => void;
  maxDate?: Date;
  minDate?: Date;
}

const DateControl = (props: DateControlProps) => {
  const [openDialog, setOpenDialog] = useState(false);

  const {
    label,
    required,
    value,
    onChange,
    error,
    helperText,
    disabled,
    maxDate,
    minDate,
    ...rest
  } = props;

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Stack spacing={1}>
      {label && <InputLabel required={required}>{label}</InputLabel>}
      <Stack spacing={1}>
        <FormControl sx={{ width: "100%" }} error={error}>
          <LocalizationProvider dateAdapter={AdapterDateFns} localeText={{ fieldMonthPlaceholder: (params) => {
            console.log("Params", params)
            if (rest?.slotProps?.textField) {
              return "MMM"
            } else {
              return "MM"
            }
          }}}>
            <DatePicker
              open={openDialog}
              onClose={handleCloseDialog}
              maxDate={maxDate}
              disabled={disabled}
              format={dateConstant.dd_MM_yyyy}
              value={value}
              onChange={(value) => onChange(value)}
              minDate={minDate}
              slotProps={{
                textField: {
                  placeholder: dateConstant.DD_MM_YYYY,
                  inputProps: {
                    readOnly: true,
                  },
                  onClick: () => handleOpenDialog()
                },
              }}
              {...rest}
            />
          </LocalizationProvider>
        </FormControl>
      </Stack>
      {error && <FormHelperText error={true}>{helperText}</FormHelperText>}
    </Stack>
  );
};

export default DateControl;
